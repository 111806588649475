div[class*="flair-s-"] {
  animation: fly var(--randomLenght) linear infinite;
}

@keyframes fly {
  0% {
    transform: translate(50vw, 50vh);
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translate(var(--randomWidth), var(--randomHeight));
    opacity: 0;
  }
}
