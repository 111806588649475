@import url('https://fonts.googleapis.com/css?family=Anton');

body {
  background: #000;
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  color: white;
  text-shadow: 2px 2px #000;
}

div[class*="flair-s-"] {
  position: absolute;
  width: var(--sizeSmall);
  height: var(--sizeSmall);
  border-radius: 50%;
  background-image: radial-gradient(#ffffff, transparent);
  filter: blur(1px);
}

.title {
  align-self: center;
  margin: 0 auto;
  display: block;
  z-index: 100;
}

span {
  font-family: Arial, sans-serif;
  font-size: 14px;
}

span.logo {
  font-family: 'Anton', sans-serif;
  font-size: 44px;
}

a:link {
  color: white;
  text-decoration: none;
  filter: blur(0.8px);
}

a:hover {
  filter: none;
}